import { Link } from 'react-router-dom';
import style from './Cart.module.sass';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const Cart = () => {
  const basket = useSelector((state) => state.basket.basket);
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(basket.length);
  }, [basket]);
  return (
    <div>
      <Link to="basket">
        <div className={style.cart__wrap}>
          <div className={style.cart}>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.6451 4.6948L3.60734 4.2358L3.4014 1.73273C3.4014 1.73273 3.4014 1.73273 3.4014 1.73273C3.38604 1.54604 3.25607 1.46875 3.17494 1.46875H1.69644C1.61081 1.46875 1.46875 1.55845 1.46875 1.76269C1.46875 1.96694 1.61081 2.05663 1.69644 2.05663H2.50965H2.97021L3.00797 2.51564L3.80559 12.2108L3.80696 12.2275L3.80722 12.2442L3.80729 12.263L3.80727 12.266C3.80793 12.2737 3.80914 12.2838 3.81128 12.2973L3.81421 12.3158L3.81574 12.3345L3.8449 12.6887L3.84492 12.689L3.84551 12.6953L3.84574 12.6974C3.92108 13.4096 4.25338 14.0165 4.7216 14.4212L5.06721 14.7199L4.80087 15.091C4.56799 15.4155 4.42574 15.8277 4.42574 16.2805C4.42574 17.3735 5.2317 18.1875 6.1319 18.1875C7.03213 18.1875 7.83808 17.3735 7.83808 16.2805C7.83808 16.0472 7.80029 15.8246 7.73193 15.6191L7.5131 14.9613H8.20637H12.1891H12.8825L12.6635 15.6192C12.5952 15.8246 12.5574 16.0471 12.5574 16.2805C12.5574 17.3735 13.3634 18.1875 14.2636 18.1875C15.1638 18.1875 15.9698 17.3735 15.9698 16.2805C15.9698 15.1875 15.1638 14.3734 14.2636 14.3734H6.13682C5.35332 14.3734 4.70239 13.8592 4.42896 13.162L4.17886 12.5243L4.86244 12.4805L16.4919 11.7348L16.492 11.7348C16.5638 11.7302 16.6663 11.6684 16.6998 11.5221L3.6451 4.6948ZM3.6451 4.6948H4.10566M3.6451 4.6948H4.10566M4.10566 4.6948H17.9598C18.012 4.6948 18.0764 4.72075 18.1267 4.79104L4.10566 4.6948ZM18.1784 5.06965L16.6998 11.522L18.1268 4.79108C18.1788 4.86385 18.2017 4.96777 18.1784 5.06957C18.1784 5.0696 18.1784 5.06963 18.1784 5.06965Z"
                stroke="#131F2E"
              />
            </svg>
          </div>
          {count > 0 ? <div className={style.count}>{count}</div> : ''}
        </div>
      </Link>
    </div>
  );
};
export default Cart;
