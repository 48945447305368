// import style from './Home.module.sass';
import Section from './Section/Section';
import Slider from './Slider/Slider';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import style from './../../components/Pagination/Pagination.module.sass';
import NextSvg from './../../assets/svg/NextSvg';
import PreviousSvg from './../../assets/svg/PreviousSvg';
const Home = () => {
  const [collections, setCollections] = useState({ results: [] });
  const [catalog, setCatalog] = useState({ results: [] });
  const [load, setLoad] = useState(false);
  const [loadCat, setLoadCat] = useState(false);
  const [url, setUrl] = useState('https://sadogroup.ru/api/collection/');
  const getData = (url) => {
    setLoad(false);
    setLoadCat(false);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setCollections(data);
        setCatalog(data);
        setLoad(true);
        setLoadCat(true);
      });
  };
  useEffect(() => {
    getData(url);
  }, []);
  useEffect(() => {
    setLoadCat(false);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setCatalog(data);
        setLoadCat(true);
      });
  }, [url]);
  const handlePageClick = (event) => {
    setUrl(`https://sadogroup.ru/api/collection/?page=${event.selected + 1}`);
  };
  return (
    <div>
      <Slider collections={collections} load={load} />
      <div className="main wrap">
        {load ? (
          <>
            {catalog.results.map((item, i) => (
              <Section {...item} key={item.id} load={loadCat} step={i} />
            ))}
            {collections.count > 9 ? (
              <div className={style.pagination__wrap}>
                <ReactPaginate
                  breakLabel="..."
                  className={style.pagination}
                  previousClassName={style.previous}
                  nextClassName={style.next}
                  key={1054}
                  initialPage={0}
                  pageClassName={style.number}
                  activeClassName={style.active}
                  nextLabel={<NextSvg />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={collections.count / 9}
                  previousLabel={<PreviousSvg />}
                  renderOnZeroPageCount={null}
                />
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <Section load={load} />
            <Section load={load} />
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
