const Telegram = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_127_2595)">
        <path
          d="M15.5 0C11.5227 0 7.70469 1.58133 4.89453 4.39336C2.08148 7.20651 0.500779 11.0217 0.5 15C0.5 18.9766 2.08203 22.7946 4.89453 25.6066C7.70469 28.4187 11.5227 30 15.5 30C19.4773 30 23.2953 28.4187 26.1055 25.6066C28.918 22.7946 30.5 18.9766 30.5 15C30.5 11.0234 28.918 7.20539 26.1055 4.39336C23.2953 1.58133 19.4773 0 15.5 0Z"
          fill="url(#paint0_linear_127_2595)"
        />
        <path
          d="M7.28956 14.8416C11.663 12.9366 14.5786 11.6805 16.0364 11.0737C20.2036 9.34101 21.0685 9.04007 21.6333 9.02987C21.7575 9.02788 22.0341 9.05858 22.2146 9.20448C22.3646 9.32753 22.4067 9.49394 22.4278 9.61077C22.4466 9.72749 22.4724 9.99351 22.4513 10.2012C22.2263 12.573 21.2489 18.3288 20.7521 20.9855C20.5435 22.1095 20.1286 22.4864 19.7278 22.5232C18.856 22.6034 18.195 21.9476 17.3513 21.3947C16.0317 20.5291 15.2864 19.9905 14.0044 19.1461C12.5232 18.1702 13.4841 17.6337 14.3278 16.7571C14.5482 16.5277 18.3872 13.0366 18.4599 12.72C18.4692 12.6804 18.4786 12.5327 18.3896 12.4549C18.3028 12.3769 18.1739 12.4036 18.0802 12.4247C17.9466 12.4547 15.8396 13.8487 11.7521 16.6066C11.1544 17.0177 10.613 17.2181 10.1255 17.2076C9.59112 17.1961 8.55987 16.9048 7.79347 16.6559C6.85597 16.3505 6.10831 16.189 6.17394 15.6703C6.20675 15.4003 6.5794 15.124 7.28956 14.8416Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_127_2595"
          x1="15.5"
          y1="0"
          x2="15.5"
          y2="30"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
        <clipPath id="clip0_127_2595">
          <rect width="30" height="30" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Telegram;
