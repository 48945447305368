import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setFilters } from './../../../redux/collectionSlice/collectionSlice';
import { useDispatch } from 'react-redux';

import ArrowSvg from '../../../assets/svg/ArrowSvg';

import style from './Filter.module.sass';

const Filter = ({ filterItems, type }) => {
  const [selectShow, setSelectShow] = useState(false);
  const filters = useSelector((state) => state.collection.filters);
  const [valueSelect, setValueSelect] = useState(type.ru);
  const dispatch = useDispatch();
  const objFilter = { ...filters };
  useEffect(() => {
    setValueSelect(filters[type.en] !== '' ? filters[type.en] : type.ru);
  }, [filters, type]);
  return (
    <div className={style.wrap}>
      <div
        className={style.filter__wrap}
        onMouseOver={() => {
          setSelectShow(true);
        }}
        onMouseLeave={() => {
          setSelectShow(false);
        }}
        onClick={() => {
          setSelectShow(!selectShow);
        }}>
        <div className={style.filter}>{valueSelect}</div>
        <div className={style.arrow + ' ' + (selectShow ? style.arrow__close : '')}>
          <ArrowSvg />
        </div>
        <div className={style.select + ' ' + (selectShow ? style.select__show : '')}>
          {filterItems &&
            filterItems.map((name, id) => (
              <label
                key={id}
                className={style.select__item}
                htmlFor={'select0' + id}
                onClick={(event) => {
                  if (event.target.innerText.substring(0, 9).trim() === 'Все') {
                    setValueSelect(type.ru);
                    objFilter[type.en] = '';
                  } else {
                    setValueSelect(event.target.innerText.substring(0, 9).trim());
                    objFilter[type.en] = event.target.innerText;
                  }
                  objFilter.page = 1;
                  dispatch(setFilters(objFilter));
                }}>
                <input
                  type="radio"
                  name="filter"
                  value={name}
                  id={'select0' + id}
                  className={style.input + ' hidden'}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                <div className={style.text}>{name}</div>
              </label>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Filter;
