import { useState } from 'react';
import { Link } from 'react-router-dom';

import Cart from './Cart/Cart';
import Nav from './Nav/Nav';
import Search from './Search/Search';
import Menu from './Menu/Menu';
import MenuMobile from './MenuMobile/MenuMobile';
import MobileSearch from './MobileSearch';
import Hamburger from './Hamburger/Hamburger';
import logo from './../assets/img/logo.svg';

import style from './Header.module.sass';

const Header = ({ menu }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [value, setValue] = useState('');
  return (
    <header className={style.main_header__wrap}>
      <div className={style.main_header}>
        <Nav />
        <div className={style.header}>
          <div className={style.nav__wrap}>
            <Hamburger setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
            <div className={style.phone}>
              <div>
                <a href="tel:+79639811288">+ 7 (963) 981 - 12 - 88</a>
              </div>
            </div>
            <div className={style.logo}>
              <Link to="/">
                <img src={logo} alt="materasso" />
              </Link>
            </div>
            <div className={style.header__end}>
              <Cart />
              <Search value={value} setValue={setValue} setShowMobileSearch={setShowMobileSearch} />
            </div>
          </div>
          {menu ? <MenuMobile menuState={menuOpen} catalog={menu} setMenuOpen={setMenuOpen} /> : ''}
        </div>
        <MobileSearch
          value={value}
          setValue={setValue}
          show={showMobileSearch}
          setShow={setShowMobileSearch}
        />
        {menu ? <Menu menu={menu} /> : ''}
      </div>
    </header>
  );
};

export default Header;
