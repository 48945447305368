import { useEffect, useState } from 'react';
import { setFilters } from './../../redux/catalogSlice/catalogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import style from './Menu.module.sass';

const MenuMobile = ({ menuState, catalog, setMenuOpen }) => {
  const [menu, setMenu] = useState([
    {
      name: 'Коллекции',
      link: '/collection',
    },
    { name: 'Sale', link: '/sale' },
  ]);
  const filters = useSelector((state) => state.catalog.filters);
  const [title, setTitle] = useState(false);
  const [menuItems, setMenuItems] = useState(menu);
  const dispatch = useDispatch();
  const onMenuClick = (item) => {
    setTitle(item.name);
    setMenuItems(item.items);
  };
  const onTitleClick = () => {
    setTitle(false);
    setMenuItems(menu);
  };

  useEffect(() => {
    setMenuItems(menu);
  }, [menu]);
  useEffect(() => {
    if (menu.length === 2 && catalog.length > 0) {
      const data = [];
      const cat = [];
      for (let item of catalog) {
        if (item.show_menu && item.products.length > 0) {
          data.push({
            name: item.name,
            id: item.id,
            items: item.products.map(({ id, name_category }) => {
              return { name: name_category, id: id };
            }),
          });
        } else if (item.show_catalog && item.products.length > 0) {
          cat.push({
            name: item.name,
            id: item.id,
            items: item.products.map(({ id, name_category }) => {
              return { name: name_category, id: id };
            }),
          });
        }
      }
      setMenu([
        {
          name: 'Каталог',
          link: '/catalog',
          items: [...cat],
        },
        ...menu,
        ...data,
        { name: 'Покупателям', link: '/buyer' },
        { name: 'О нас', link: '/about' },
        { name: 'Контакты', link: '/contacts' },
      ]);
    }
  }, [catalog, menu]);

  const changeId = (id, name) => {
    dispatch(setFilters({ categoryId: id, name: name, subId: false, page: 1, sort: filters.sort }));
  };
  return (
    <div className={style.menu__wrap}>
      <div className={menuState ? style.open : ''}>
        <div className={style.menu__mobile}>
          {title ? (
            <div className={style.menu__title} onClick={onTitleClick}>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#131F2E"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {title}
              <div></div>
            </div>
          ) : (
            ''
          )}
          <div className="wrap">
            <div className={style.menu}>
              {menuItems.map((item, i) => (
                <Link
                  to={item.link ? item.link : `catalog?category=${item.id}`}
                  className={style.menu__item}
                  onClick={
                    item.items
                      ? () => onMenuClick(item)
                      : () => {
                          setMenuOpen(false);
                          item.id && changeId(item.id, item.name);
                        }
                  }
                  key={i}>
                  {item.name}
                  {item.items ? (
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1 13L7 7L1 1"
                        stroke="#131F2E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    ''
                  )}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MenuMobile;
