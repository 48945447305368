import { Link } from 'react-router-dom';
import style from './../Menu.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from './../../../redux/catalogSlice/catalogSlice';
import { setCollection } from './../../../redux/collectionSlice/collectionSlice';
import { setPage } from '../../../redux/saleSlice/saleSlice';
const DropMenu = ({ menu, catalog, collection }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.catalog.filters);
  const handleLinkCLick = (id) => {
    dispatch(
      setCollection({
        page: 1,
        collection: id,
        material: '',
        color: '',
        style: '',
        quantity: '',
        price: { min: '', max: '' },
      }),
    );
  };

  return (
    <div className={style.dropdown__wrap}>
      {catalog ? (
        <div>
          {collection ? (
            <div className={style.dropdown + ' ' + style.collection}>
              {menu.map(({ name, id, images }) => (
                <Link
                  to={`../collcatalog/?collection=${id}`}
                  onClick={() => handleLinkCLick(id)}
                  className={style.dropdown__item + ' ' + style.collection__item}
                  key={id}>
                  <div
                    className={style.image}
                    style={{ backgroundImage: `url(${images[0].image})` }}></div>
                  <div className={style.collection__title}>{name}</div>
                </Link>
              ))}
            </div>
          ) : (
            <div className={style.dropdown}>
              {menu.map(({ name, id, show_catalog, products }) =>
                show_catalog ? (
                  <div className={style.dropdown__item} key={id}>
                    <div className={style.dropdown__title}>{name}</div>
                    {products
                      ? products.map((item) => (
                          <div
                            className={style.dropdown__menu}
                            key={item.id}
                            onClick={() => {
                              dispatch(
                                setFilters({
                                  categoryId: item.id,
                                  name: item.name_category,
                                  subId: false,
                                  page: 1,
                                  sort: filters.sort,
                                }),
                              );
                              dispatch(setPage(1));
                            }}>
                            <Link to={`catalog?category=${item.id}`}>{item.name_category}</Link>
                          </div>
                        ))
                      : ''}
                  </div>
                ) : (
                  ''
                ),
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={style.dropdown + ' ' + style.dropdown__small}>
          {menu.map(({ id, name_category }) => (
            <div
              className={style.dropdown__menu}
              key={id}
              onClick={() => {
                dispatch(
                  setFilters({
                    categoryId: id,
                    name: name_category,
                    subId: false,
                    page: 1,
                    sort: filters.sort,
                  }),
                );
              }}>
              <Link to={`catalog?category=${id}`}>{name_category}</Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropMenu;
