const WhatsApp = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_127_2593)">
        <mask id="path-1-inside-1_127_2593" fill="white">
          <path d="M1.14023 14.8205C1.13953 17.341 1.80328 19.8022 3.06539 21.9715L1.01953 29.3834L8.66391 27.3945C10.7782 28.5366 13.1472 29.1351 15.5545 29.1352H15.5609C23.5079 29.1352 29.977 22.7186 29.9804 14.8317C29.982 11.01 28.4834 7.41628 25.7606 4.71255C23.0384 2.00907 19.4179 0.519414 15.5603 0.51767C7.61227 0.51767 1.14363 6.93395 1.14035 14.8205" />
        </mask>
        <path
          d="M1.14023 14.8205C1.13953 17.341 1.80328 19.8022 3.06539 21.9715L1.01953 29.3834L8.66391 27.3945C10.7782 28.5366 13.1472 29.1351 15.5545 29.1352H15.5609C23.5079 29.1352 29.977 22.7186 29.9804 14.8317C29.982 11.01 28.4834 7.41628 25.7606 4.71255C23.0384 2.00907 19.4179 0.519414 15.5603 0.51767C7.61227 0.51767 1.14363 6.93395 1.14035 14.8205"
          fill="url(#paint0_linear_127_2593)"
        />
        <path
          d="M3.06539 21.9715L4.02934 22.2376L4.14125 21.8322L3.92974 21.4686L3.06539 21.9715ZM1.01953 29.3834L0.0555789 29.1173L-0.405408 30.7874L1.27132 30.3512L1.01953 29.3834ZM8.66391 27.3945L9.13917 26.5147L8.79295 26.3277L8.41212 26.4268L8.66391 27.3945ZM15.5545 29.1352L15.5545 30.1352H15.5545V29.1352ZM29.9804 14.8317L30.9804 14.8322V14.8321L29.9804 14.8317ZM25.7606 4.71255L25.056 5.4221L25.056 5.42213L25.7606 4.71255ZM15.5603 0.51767L15.5607 -0.48233H15.5603V0.51767ZM0.140234 14.8202C0.139482 17.5172 0.849874 20.152 2.20104 22.4744L3.92974 21.4686C2.75669 19.4524 2.13958 17.1649 2.14023 14.8207L0.140234 14.8202ZM2.10144 21.7054L0.0555789 29.1173L1.98348 29.6495L4.02934 22.2376L2.10144 21.7054ZM1.27132 30.3512L8.91569 28.3623L8.41212 26.4268L0.767743 28.4156L1.27132 30.3512ZM8.18864 28.2744C10.4492 29.4955 12.9815 30.1351 15.5545 30.1352L15.5546 28.1352C13.3129 28.1351 11.1073 27.5778 9.13917 26.5147L8.18864 28.2744ZM15.5545 30.1352H15.5609V28.1352H15.5545V30.1352ZM15.5609 30.1352C24.0528 30.1352 30.9768 23.2784 30.9804 14.8322L28.9804 14.8313C28.9773 22.1588 22.9631 28.1352 15.5609 28.1352V30.1352ZM30.9804 14.8321C30.9821 10.7426 29.3763 6.89368 26.4653 4.00297L25.056 5.42213C27.5904 7.93887 28.9818 11.2774 28.9804 14.8313L30.9804 14.8321ZM26.4653 4.003C23.5548 1.11259 19.6807 -0.480467 15.5607 -0.48233L15.5598 1.51767C19.155 1.5193 22.5219 2.90554 25.056 5.4221L26.4653 4.003ZM15.5603 -0.48233C7.06745 -0.48233 0.143866 6.37414 0.140352 14.82L2.14035 14.8209C2.1434 7.49376 8.15709 1.51767 15.5603 1.51767V-0.48233Z"
          fill="white"
          mask="url(#path-1-inside-1_127_2593)"
        />
        <path
          d="M0.625391 14.8158C0.62457 17.4271 1.31211 19.9763 2.61922 22.2233L0.5 29.9008L8.41848 27.8407C10.6003 29.021 13.0568 29.6434 15.5564 29.6443H15.5628C23.795 29.6443 30.4965 22.9969 30.5 14.8277C30.5014 10.8686 28.9489 7.1457 26.1289 4.34512C23.3086 1.54488 19.5586 0.00162791 15.5628 0C7.32922 0 0.628672 6.64651 0.625391 14.8158ZM5.34102 21.8363L5.04535 21.3706C3.80246 19.4097 3.14645 17.1436 3.14738 14.8167C3.15008 8.02663 8.7193 2.50233 15.5675 2.50233C18.8839 2.50372 22.0006 3.78651 24.3448 6.11395C26.6889 8.44163 27.9788 11.5358 27.978 14.8267C27.975 21.6169 22.4056 27.1419 15.5628 27.1419H15.5579C13.3298 27.1407 11.1446 26.547 9.23891 25.425L8.78539 25.1581L4.08641 26.3806L5.34102 21.8362V21.8363Z"
          fill="url(#paint1_linear_127_2593)"
        />
        <path
          d="M11.8293 8.62186C11.5497 8.00523 11.2554 7.99279 10.9895 7.98197C10.7718 7.97267 10.5229 7.97337 10.2742 7.97337C10.0253 7.97337 9.6209 8.06628 9.27906 8.43663C8.93687 8.80732 7.97266 9.70314 7.97266 11.5251C7.97266 13.3472 9.31012 15.108 9.49656 15.3553C9.68324 15.6022 12.0786 19.4608 15.8721 20.9452C19.025 22.1788 19.6666 21.9335 20.3508 21.8716C21.0352 21.81 22.5591 20.976 22.87 20.1113C23.1811 19.2466 23.1811 18.5055 23.0878 18.3506C22.9946 18.1963 22.7457 18.1036 22.3724 17.9185C21.9991 17.7333 20.1641 16.8373 19.8221 16.7137C19.4799 16.5902 19.2311 16.5286 18.9822 16.8994C18.7333 17.2697 18.0185 18.1036 17.8007 18.3506C17.5831 18.5981 17.3652 18.629 16.9921 18.4437C16.6186 18.2579 15.4166 17.8673 13.9906 16.6058C12.8811 15.6242 12.132 14.412 11.9143 14.0412C11.6965 13.6709 11.8909 13.4702 12.0781 13.2857C12.2458 13.1198 12.4514 12.8533 12.6382 12.6371C12.8243 12.4208 12.8864 12.2665 13.0109 12.0195C13.1355 11.7723 13.0731 11.556 12.98 11.3708C12.8864 11.1856 12.1612 9.35407 11.8293 8.62186Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_127_2593"
          x1="15.5"
          y1="29.3834"
          x2="15.5"
          y2="0.51767"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#1FAF38" />
          <stop offset="1" stopColor="#60D669" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_127_2593"
          x1="15.5"
          y1="29.9008"
          x2="15.5"
          y2="0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_127_2593">
          <rect width="30" height="30" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default WhatsApp;
