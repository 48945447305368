import { useDispatch } from 'react-redux';
import { setSearch } from '../../redux/searchSlice/searchSlice';
import { useNavigate } from 'react-router-dom';

import { useEffect, useRef } from 'react';

import svg from './../../assets/img/search.svg';

import style from './Search.module.sass';

const MobileSearch = ({ value, setValue, show, setShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const Search = (event) => {
    event.preventDefault();
    if (show) {
      dispatch(setSearch({ value: value, page: 1 }));
      if (value.trim().length > 0) {
        navigate('/search');
      }
    } else {
      setShow(true);
    }
  };
  useEffect(() => {
    ref.current.focus();
  }, [show]);
  return (
    <form className={style.search + ' ' + (show ? style.active : style.hidden)} onSubmit={Search}>
      <div className={style.icon} onClick={Search}>
        <img src={svg} alt="" />
      </div>
      <input
        ref={ref}
        type="text"
        value={value}
        className={style.input + ' ' + (show ? style.input__active : '')}
        onChange={(event) => setValue(event.target.value)}
        placeholder="Поиск товара"
        onKeyDown={(event) => event.key === 'Enter' && Search(event)}
      />
    </form>
  );
};
export default MobileSearch;
